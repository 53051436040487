import React from 'react';


const Signin = React.lazy(() => import('./Components/Authentication/SignIn/SignIn1'));
const ResetPassword1 = React.lazy(() => import('./Components/Authentication/ResetPassword/ResetPassword1'));
const ChangePassword = React.lazy(() => import('./Components/Authentication/ChangePassword'));

const PrivacyPolicy = React.lazy(() => import('./Components/Pages/PrivacyPolicy'));
const PrivacyPolicyForWebsite = React.lazy(() => import('./Components/Pages/PrivacyPolicyForWebsite'));
const TermsAndCondition = React.lazy(() => import('./Components/Pages/TermsAndCondition'));

const route = [
    { path: '/auth/signin', exact: true, name: 'Signin 1', component: Signin },
    { path: '/auth/reset-password', exact: true, name: 'Reset Password ', component: ResetPassword1 },
    { path: '/auth/change-password', exact: true, name: 'Change Password ', component: ChangePassword },
    
    { path: '/pages/privacy-policy', exact: true, name: 'Privacy Policy for Motorento Driving ', component: PrivacyPolicy },
    { path: '/pages/privacy-policy-for-website', exact: true, name: 'Privacy Policy for Website ', component: PrivacyPolicyForWebsite },
    { path: '/pages/terms-and-condition', exact: true, name: 'Terms and Condition for Motorento Driving ', component: TermsAndCondition },
];

export default route;