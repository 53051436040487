export default {
    defaultPath: '/auth/signin',
    basename: '', // only at build time to set, like //next/react/
    layout: 'vertical', // vertical, horizontal
    subLayout: '', // horizontal-2
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    headerBackColor: 'background-green', // background-blue, background-red, background-purple, background-info, background-green, background-dark, background-grd-blue, background-grd-red, background-grd-purple, background-grd-info, background-grd-green, background-grd-dark, background-img-1, background-img-2, background-img-3, background-img-4, background-img-5, background-img-6
    rtlLayout: false,
    navFixedLayout: false,
    headerFixedLayout: false,
    boxLayout: false,
    renderSetTimeout: 100,//in ms 1000ms=1sec
    // googleTranslateAPIKey: "AIzaSyAtFU_1SeEgelZ9DlfYg0Pl_ErU38GNgKc",
    // googleMapAPIKey: "AIzaSyBUX_6Nh_35nNO-3mdFOfSh-ufUR-stMGI",
    // googleMapAPIKey: "AIzaSyAtFU_1SeEgelZ9DlfYg0Pl_ErU38GNgKc",
    googleMapAPIKey: "AIzaSyCqsqSwQZhJNFljIycTs_J-USlNOSgjbUI",


    googleMapCenterPoint: {
        lng: 120.984222 ,
        lat: 14.599512
    },


    apiURL:'https://api.dev.motorento.pegotec.dev',
    // apiURL: 'http://127.0.0.1:8000',
};
//
